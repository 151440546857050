<template>
    <div class="basicSetup">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>升级条件</el-breadcrumb-item>
      </el-breadcrumb>
      <el-form ref="form" label-width="150px" :rules="formListRules" :model="formList">
        <el-form-item label="佣金总额" prop="profitTotal"
          ><el-input v-model="formList.profitTotal" type="number"></el-input
        ></el-form-item>
        <!-- <el-form-item label="外部店长提现是否需要审核">
             <el-radio v-model="formList.base_is_out_exam" :label="1">是</el-radio>
              <el-radio v-model="formList.base_is_out_exam" :label="0">否</el-radio>
          </el-form-item> -->
        <el-form-item label="注册店长数量" prop="userTotal"
          ><el-input v-model="formList.userTotal" type="number"></el-input
        ></el-form-item>
        <el-form-item label="自购订单+直销订单" prop="orderTotal"
          ><el-input v-model="formList.orderTotal" type="number"></el-input
        ></el-form-item>
        <el-form-item label="自购金额+直销金额" prop="feeTotal"
          ><el-input v-model="formList.feeTotal" type="number"></el-input
        ></el-form-item>
        <el-form-item label="二级分销金额总和" prop="secondFee"
          ><el-input v-model="formList.secondFee" type="number"></el-input
        ></el-form-item>
        <el-form-item label="二级分销订单数量" prop="secondOrder"
          ><el-input v-model="formList.secondOrder" type="number"></el-input
        ></el-form-item>
        <el-form-item label="">
          <el-button type="success" @click="onSave">保存</el-button>
          <el-button type="primary" @click="goBack">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </template>
  <script>
  import { getEscalationCondition, modifyEscalationCondition } from "../../api/wanxiaoShop";
  export default {
    name: "basicSetup",
    components: {
    },
    data() {
      return {
        formList: {
            profitTotal: null,
            userTotal: null,
            orderTotal: null,
            feeTotal: null,
            secondFee: null,
            secondOrder: null,
        },
        formListRules: {
            profitTotal: [
          { required: true, message: "请输入佣金总额", trigger: "blur" },
        ],
        userTotal: [
          { required: true, message: "请输入注册店长数量", trigger: "blur" },
        ],
        orderTotal: [
          { required: true, message: "请输入自购订单+直销订单", trigger: "blur" },
        ],
        feeTotal: [
          { required: true, message: "请输入直销金额+自购金额", trigger: "blur" },
        ],
        secondFee: [
          { required: true, message: "请输入二级分销金额总和", trigger: "blur" },
        ],
        secondOrder: [
          { required: true, message: "请输入二级分销订单数量", trigger: "blur" },
        ],
      },
      };
    },
    created() {
      this.getEscalation();
    },
    methods: {
        async getEscalation(){
            const { data } = await getEscalationCondition({levelId:this.$route.query.levelId})
            this.formList = {...this.formList,...data.data}
        },
      async onSave() {
        const { data } = await modifyEscalationCondition({
            ...this.formList,
            levelId:this.$route.query.levelId
        });
        if (data.code == 0) {
          this.$message({
            message: data.msg,
            type: "success",
          });
          this.goBack()
        } else {
          this.$message({
            message: data.msg,
            type: "error",
          });
        }
      },
      goBack() {
      this.$router.go(-1);
    },
    },
  };
  </script>
  <style lang="less" scoped>
  .basicSetup {
    .el-breadcrumb {
      height: 35px;
      border-bottom: 1px solid rgb(192, 191, 191);
    }
  
    /deep/ .el-form {
      margin-top: 50px;
      .el-form-item {
        .el-form-item__label {
          width: 150px !important;
        }
        .el-form-item__content {
        //   margin-left: 120px !important;
          width: 650px;
          .richEdit {
            height: 650px;
            .ql-container {
              height: 450px;
            }
          }
        }
      }
    }
  }
  </style>
  